import "./Contacts.scss";

export interface contact_i {
  title: string;
  email: string;
}

export const Contacts: React.FC<{ contacts: contact_i[] }> = ({ contacts }) => {
  return (
    <div className="contacts">
      <div className="contacts-title">CONTACTS:</div>
      <div className="contacts-list">
        {contacts.map((contact, key) => {
          return (
            <div className="contacts-list-element" key={key}>
              <div className="contacts-list-element-title">{contact.title}</div>
              <div className="contacts-list-element-email">{contact.email}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
