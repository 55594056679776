import {
  contactos,
  oQueEOImprevisto,
  orgaos,
  queroSerSocio,
} from "../../store/quemSomosData";
import { Paragraph } from "../../components/Paragraph/Paragraph";
import "./QuemSomos.scss";

export default function QuemSomos() {
  return (
    <div className="quemSomos">
      <Paragraph
        paragraph={oQueEOImprevisto}
        className=""
        id="oQueEOImprevisto-subTitle"
      />
      <Paragraph
        paragraph={queroSerSocio}
        className=""
        id="queroSerSocio-subTitle"
      />
      <Paragraph paragraph={orgaos} className="" id="orgaos-subTitle" />
      <Paragraph paragraph={contactos} className="" id="contactos-subTitle" />
    </div>
  );
}
