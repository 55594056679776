const images = [
  {
    imgURL: "/sliderImages/paneImage.png",
    imgAlt: "img-1",
  },
  {
    imgURL: "/sliderImages/oqueauniversidade.png",
    imgAlt: "img-2",
  },
  {
    imgURL: "/sliderImages/miguelmanara.png",
    imgAlt: "img-3",
  },
];

export default images;
