import { useEffect, useState } from "react";
import "./Calendar.scss";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import {
  event_i,
  existsEventByDate,
  getEventsByDate,
} from "../../store/eventsData";

export default function Calendar() {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const date = new Date();

  const [selectedDate, setSelectedDate] = useState({
    date: date.getDate(),
    month: months[date.getMonth()],
    year: date.getFullYear(),
  });

  const [events, setEvents] = useState<event_i[]>(
    getEventsByDate(
      selectedDate.year,
      months.indexOf(selectedDate.month),
      selectedDate.date
    )
  );

  const [numberOfDays, setNumberOfDays] = useState(31);

  useEffect(() => {
    if (
      selectedDate.month === "Janeiro" ||
      selectedDate.month === "Março" ||
      selectedDate.month === "Maio" ||
      selectedDate.month === "Julho" ||
      selectedDate.month === "Agosto" ||
      selectedDate.month === "Outubro" ||
      selectedDate.month === "Dezembro"
    ) {
      setNumberOfDays(31);
    } else if (selectedDate.month === "Fevereiro") {
      if (selectedDate.year % 4 === 0) {
        setNumberOfDays(29);
      } else {
        setNumberOfDays(28);
      }
    } else {
      setNumberOfDays(30);
    }

    setEvents(
      getEventsByDate(
        selectedDate.year,
        months.indexOf(selectedDate.month),
        selectedDate.date
      )
    );
  }, [selectedDate, months]);

  return (
    <div className="datepicker">
      <div className="datepicker-top">
        <div className="datepicker-top-month-selector">
          <button
            className="datepicker-top-month-selector-arrow"
            onClick={() => {
              if (
                months.indexOf(selectedDate.month) === date.getMonth() &&
                selectedDate.year === date.getFullYear()
              )
                return;
              if (selectedDate.month === "Janeiro") {
                setSelectedDate({
                  date: 1,
                  month: "Dezembro",
                  year: selectedDate.year - 1,
                });
              } else {
                setSelectedDate({
                  date: 1,
                  month: months[months.indexOf(selectedDate.month) - 1],
                  year: selectedDate.year,
                });
              }
            }}
          >
            <FaArrowLeft color="orange" />
          </button>
          <span className="datepicker-top-month-selector-month-name">
            {selectedDate.month + " " + selectedDate.year}
          </span>
          <button
            className="datepicker-top-month-selector-arrow"
            onClick={() => {
              if (selectedDate.month === "Dezembro") {
                setSelectedDate({
                  date: 1,
                  month: "Janeiro",
                  year: selectedDate.year + 1,
                });
              } else {
                setSelectedDate({
                  date: 1,
                  month: months[months.indexOf(selectedDate.month) + 1],
                  year: selectedDate.year,
                });
              }
            }}
          >
            <FaArrowRight color="orange" />
          </button>
        </div>
      </div>
      <div className="datepicker-calendar">
        <span className="datepicker-calendar-day">Dom</span>
        <span className="datepicker-calendar-day">Seg</span>
        <span className="datepicker-calendar-day">Ter</span>
        <span className="datepicker-calendar-day">Qua</span>
        <span className="datepicker-calendar-day">Qui</span>
        <span className="datepicker-calendar-day">Sex</span>
        <span className="datepicker-calendar-day">Sab</span>
        {[
          ...Array(
            new Date(
              selectedDate.year,
              months.indexOf(selectedDate.month),
              1
            ).getDay()
          ).keys(),
        ].map((day) => {
          return <span key={day} className="datepicker-calendar-date"></span>;
        })}
        {[...Array(numberOfDays).keys()].map((day) => {
          return (
            <button
              key={day}
              className={
                "datepicker-calendar-date " +
                (date.getDate() === day + 1 &&
                  date.getMonth() === months.indexOf(selectedDate.month) &&
                  date.getFullYear() === selectedDate.year &&
                  " datepicker-calendar-current-day ") +
                (existsEventByDate(
                  selectedDate.year,
                  months.indexOf(selectedDate.month),
                  day + 1
                ) && " datepicker-calendar-event")
              }
              onClick={() => {
                setSelectedDate({
                  date: day + 1,
                  month: selectedDate.month,
                  year: selectedDate.year,
                });
              }}
            >
              {day + 1}
            </button>
          );
        })}
      </div>
      <div className="datepicker-events">
        <div className="datepicker-events-date">
          {"Dia " + selectedDate.date + " de " + selectedDate.month}
        </div>
        {events.length === 0 && (
          <div className="datepicker-events-event">Nenhum evento neste dia</div>
        )}
        {events.map((event, key) => {
          return (
            <div key={key} className="datepicker-events-event">
              <div>{event.type + ": "}</div>
              <div>{event.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
