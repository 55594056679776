import Slide from "../../components/Slide/Slide";
import "./Home.scss";
import images from "../../store/images";

export default function Home() {
  return (
    <div className="home">
      <Slide>
        {images.map((image, index) => {
          return <img key={index} src={image.imgURL} alt={image.imgAlt} />;
        })}
      </Slide>
    </div>
  );
}
