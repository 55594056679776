import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Logo.scss";

export default function Logo() {
  const [logoHeight, setLogoHeight] = useState(0);
  useEffect(() => {
    function updateLogoHeight() {
      setLogoHeight((window.innerHeight * 70) / 100);
    }

    // Attach the event listener to window resize
    window.addEventListener("resize", updateLogoHeight);

    // Call it initially to get the initial values
    updateLogoHeight();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateLogoHeight);
    };
  }, []);
  return (
    <div className="logoComponent">
      <Link to={"/"}>
        <img src="/logo.png" height={logoHeight} alt="logo image"></img>
      </Link>
    </div>
  );
}
