import { Link } from "react-router-dom";
import "./NavBarPopUp.scss";

interface Props {
  buttons: Array<[string, string]>;
}

export default function NavBarPopUp({ buttons }: Props) {
  return (
    <div className="boxOfOptButtons">
      {buttons.map(([buttonName, buttonLink], index) => (
        <Link key={index} to={buttonLink} className="buttonOpt">
          {buttonName}
        </Link>
      ))}
    </div>
  );
}
