import { RoundButtonBW } from "../Buttons/roundButton/roundButton";
import { Contacts, contact_i } from "./Contacts/Contacts";
import "./Footer.scss";
import { BsInstagram, BsYoutube, BsSpotify } from "react-icons/bs";

export default function Footer() {
  const contacts: contact_i[] = [
    { title: "DIREÇÃO", email: "direcao@imprevisto.pt" },
    { title: "MESA DA ASSEMBLEIA", email: "mesa@imprevisto.pt" },
    { title: "COMUNICAÇÃO", email: "comunicacao@imprevisto.pt" },
    { title: "O TROCO-JORNAL", email: "troco@imprevisto.pt" },
  ];

  return (
    <div className="footer">
      <div className="footer-contacts">
        <Contacts contacts={contacts} />
      </div>
      <div className="footer-partner">
        <RoundButtonBW text="QUERES SER SÓCIO?" />
      </div>
      <div className="footer-donations">
        <RoundButtonBW text="DONATIVO" />
      </div>
      <div className="footer-socials">
        <a href="" style={{ color: "grey" }}>
          <BsYoutube size="3.5vh" />
        </a>
        <a
          href="https://www.instagram.com/ac.imprevisto/"
          target="_blank"
          style={{ color: "white" }}
        >
          <BsInstagram size="3.5vh" />
        </a>
        <a
          href="https://open.spotify.com/show/07VSw2fYZRsXNwNl2Dtbff"
          target="_blank"
          style={{ color: "white" }}
        >
          <BsSpotify size="3.5vh" />
        </a>
      </div>
    </div>
  );
}
