import "./NavBar.scss";
import { Helmet } from "react-helmet";
import NavBarPopUp from "./popUps/NavBarPopUp";
import { Link, useLocation } from "react-router-dom";

export default function NavBar() {
  const pathname = useLocation().pathname;

  return (
    <div className={`navBar ${pathname === "/" ? "noBorder" : ""}`}>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital@1&display=swap"
        ></link>
      </Helmet>
      <div className="navBar-box">
        <Link to={"/quemSomos"}>
          <button
            className={`navBar-box-button ${
              pathname === "/quemSomos" ? "boldButton" : ""
            }`}
            onClick={() => {}}
          >
            QUEM SOMOS?
          </button>
        </Link>
        <NavBarPopUp
          buttons={[
            ["O QUE É O IMPREVISTO?", "/quemSomos"],
            ["QUERO SER SÓCIO", "/quemSomos"],
            ["ORGÃOS", "/quemSomos"],
            ["CONTACTOS", "/quemSomos"],
          ]}
        />
      </div>
      <div className="navBar-box">
        <Link to={"/transparencia"}>
          <button
            className={`navBar-box-button ${
              pathname === "/transparencia" ? "boldButton" : ""
            }`}
            onClick={() => {}}
          >
            TRANSPARÊNCIA
          </button>
        </Link>
        <NavBarPopUp
          buttons={[
            ["ESTATUTOS", "/transparencia"],
            ["ATAS", "/transparencia"],
            ["FINANÇAS", "/transparencia"],
          ]}
        />
      </div>
      <div className="navBar-box">
        <Link to={"/proximasAtividades"}>
          <button
            className={`navBar-box-button ${
              pathname === "/proximasAtividades" ? "boldButton" : ""
            }`}
            onClick={() => {}}
          >
            PRÓXIMAS ATIVIDADES
          </button>
        </Link>
      </div>
      <div className="navBar-box">
        <button
          className={`navBar-box-button ${
            pathname === "/troco" ? "boldButton" : ""
          }`}
          onClick={() => {}}
        >
          TROCO
        </button>
        <NavBarPopUp
          buttons={[
            ["O QUE É O TROCO?", "/"],
            ["EQUIPA", "/"],
            ["CALL FOR PAPERS", "/"],
            ["PODCAST", "/"],
            ["QUERO SER ASSINANTE", "/"],
            ["CONTACTOS", "/"],
          ]}
        />
      </div>
      <div className="navBar-box">
        <Link to={"/arquivo"}>
          <button
            className={`navBar-box-button ${
              pathname === "/arquivo" ? "boldButton" : ""
            }`}
            onClick={() => {}}
          >
            ARQUIVO
          </button>
        </Link>
        <NavBarPopUp
          buttons={[
            ["CONFERÊNCIAS", "/arquivo"],
            ["EXPOSIÇÕES", "/arquivo"],
            ["APRESENTAÇÕES DE LIVROS", "/arquivo"],
            ["O TROCO", "/arquivo"],
            ["GALERIA", "/arquivo"],
          ]}
        />
      </div>
    </div>
  );
}
