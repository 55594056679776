import { eventInfo, event_i } from "../../../store/eventsData";
import { IoIosArrowBack } from "react-icons/io";
import "./CardInfo.scss";
import { useNavigate } from "react-router-dom";

export const CardInfo: React.FC<{}> = () => {
  const info: event_i | null = eventInfo;
  const navigate = useNavigate();

  if (!info) return <div></div>;
  return (
    <div className="card-description">
      <div className="card-description-header">
        <div
          className="card-description-header-backBtn"
          onClick={() => {
            navigate("/arquivo");
          }}
        >
          <IoIosArrowBack size={40} />
        </div>
        <div className="card-description-header-title">{info.title}</div>
      </div>
      <div className="card-description-body">
        <div className="card-description-body-image">
          <img
            src={info.secondImage ? info.secondImage : info.image}
            alt="event"
            height={600}
            className="card-description-body-image-img"
          />
        </div>
        <div className="card-description-body-comment">
          <div className="card-description-body-comment-text">
            <div className="card-description-body-comment-text-title">
              {info.commentTitle}
            </div>
            <div className="card-description-body-comment-text-content">
              {info.comment}
            </div>
            <div className="card-description-body-comment-text-commentAuthor">
              {info?.commentAuthor}
            </div>
          </div>
          <div className="card-description-body-comment-buttons"></div>
        </div>
      </div>
    </div>
  );
};
