import { RoundButton } from "../Buttons/roundButton/roundButton";
import "./Paragraph.scss";

export interface paragraph_i {
  title: string;
  titleSize:
    | "xx-large"
    | "x-large"
    | "larger"
    | "large"
    | "medium"
    | "small"
    | "smaller"
    | "x-small"
    | "xx-small";
  titleWeight: number;
  paragraph: string;
  paragraphSize:
    | "xx-large"
    | "x-large"
    | "larger"
    | "large"
    | "medium"
    | "small"
    | "smaller"
    | "x-small"
    | "xx-small";
  paragraphWeight: number;
  button: string;
  subParagraph: paragraph_i[];
}

export const Paragraph: React.FC<{
  paragraph: paragraph_i;
  className: string;
  id: string;
}> = ({ paragraph, className, id }) => {
  if (className === "") className = "paragraphComponent";

  return (
    <div className={className} id={id}>
      <div
        className={className + "-title"}
        style={{
          fontSize: paragraph.titleSize,
          fontWeight: paragraph.titleWeight,
        }}
      >
        {paragraph.title}
      </div>
      {paragraph.paragraph !== "" && (
        <div
          className={className + "-paragraph"}
          style={{
            fontSize: paragraph.paragraphSize,
            fontWeight: paragraph.paragraphWeight,
          }}
        >
          {paragraph.paragraph}
        </div>
      )}

      {paragraph.button !== "" && (
        <div className={className + "-button"}>
          <RoundButton text={paragraph.button} />
        </div>
      )}
      {paragraph.subParagraph.map((par) => {
        return (
          <Paragraph paragraph={par} className={className + "-sub"} id="" />
        );
      })}
    </div>
  );
};
