import { useState } from "react";
import "./Card.scss";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { event_i, setEventInfo } from "../../store/eventsData";

export const Card: React.FC<{
  title: string;
  image: string;
  date: string;
  hour: string;
  type: "nextActivity" | "archived";
  info: event_i;
}> = ({ title, image, date, hour, type, info }) => {
  const [popUp, setPopUp] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <div className="card">
      <div
        className="card-image"
        onClick={() => {
          if (type === "nextActivity") setPopUp(true);
          else {
            setEventInfo(info);
            navigate("/arquivo-evento");
          }
        }}
      >
        <img src={image} alt="event" height={250} className="card-image-img" />
      </div>
      <div className="card-date">{date}</div>
      <div className="card-hour">{hour}</div>
      <div className="card-title">{title}</div>
      {type === "nextActivity" && (
        <div
          className="card-popUp"
          style={{ display: popUp ? "block" : "none" }}
        >
          <div className="card-popUp-img">
            <img
              src={image}
              alt="event"
              height={800}
              className="card-popUp-img-image"
            />
            <div className="card-popUp-img-buttons">
              <RxCross1
                size={50}
                className="card-popUp-img-buttons-btn"
                onClick={() => {
                  setPopUp(false);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
