import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import NavBar from "./components/NavBar/NavBar";
import Logo from "./components/Logo/Logo";
import QuemSomos from "./pages/QuemSomos/QuemSomos";
import ProximasAtividades from "./pages/ProximasAtividades/ProximasAtividades";
import Arquivo from "./pages/Arquivo/Arquivo";
import { CardInfo } from "./components/Card/CardInfo/CardInfo";
import { Donativo } from "./components/Donativo/Donativo";
import Edit from "./pages/Edit/Edit";

export default function App() {
  return (
    <Router>
      <div className="container">
        <div className="container-logo">
          <Logo />
        </div>
        <div className="container-navBar">
          <NavBar />
        </div>
        <div className="container-body">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/quemSomos" element={<QuemSomos />} />
            <Route
              path="/proximasAtividades"
              element={<ProximasAtividades />}
            />
            <Route path="/arquivo" element={<Arquivo />} />
            <Route path="/arquivo-evento" element={<CardInfo />} />
            <Route path="/queres-ser-socio" />
            <Route path="/donativo" element={<Donativo />} />
            <Route path="/edit-page" element={<Edit />} />
          </Routes>
        </div>
        <div className="container-footer">
          <Footer />
        </div>
      </div>
    </Router>
  );
}
