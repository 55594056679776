import { paragraph_i } from "../components/Paragraph/Paragraph";

export const oQueEOImprevisto: paragraph_i = {
  title: "O QUE É O IMPREVISTO?",
  titleSize: "larger",
  titleWeight: 800,
  paragraph: `A Associação Cultural Imprevisto foi constituída a 13 de Outubro de 2022. Ela nasceu no seguimento de
	uma história que remonta a uma série de encontros culturais, intitulada Encontros das Estrelas,
	organizada via zoom durante o primeiro confinamento, em 2020. Este foi um espaço que proporcionou a
	oportunidade de pôr em comum os vários interesses culturais dos seus participantes. Desde aí, nasceu
	um livro. O que tem a ver com as Estrelas?; nasceu uma revista, O Troco; e nasce agora o Imprevisto.
	Assim, o Imprevisto parte de uma tomada de consciência de que a vida assim vivida gera uma cultura
	nova, cultura que não é para morrer entre nós – aliás, sendo assim não seria cultura – mas que é para
	chegar a todos. Nesse sentido, a criação de uma Associação Cultural Juvenil surgiu como meio
	conveniente para cumprir tal propósito.
	O nome da Associação vem de um verso do poeta italiano Eugenio Montale, do poema “Il viaggio” – já
	com tudo preparado para a viagem, esperamos o trovão que nos diga que a realidade é maior do que
	aquilo que imaginamos e planeámos: “um imprevisto é a única esperança”.`,
  paragraphSize: "medium",
  paragraphWeight: 500,
  button: "",
  subParagraph: [],
};

export const queroSerSocio: paragraph_i = {
  title: "QUERO SER SÓCIO",
  titleSize: "larger",
  titleWeight: 800,
  paragraph: `Queres ser sócio? No butão abaixo poderás submeter o teu pedido de
	admissão como sócio da Associação Cultural Imprevisto. A tua admissão está
	sujeita a aceitação por parte da Direção da Associação.
	Os deveres e direitos dos sócios estão descritos nos Estatutos, que
	recomendamos ler com atenção antes da submissão do formulário.`,
  paragraphSize: "medium",
  paragraphWeight: 500,
  button: "Pedir admissão como sócio",
  subParagraph: [],
};

export const orgaos: paragraph_i = {
  title: "ORGÃOS",
  titleSize: "larger",
  titleWeight: 800,
  paragraph: ``,
  paragraphSize: "medium",
  paragraphWeight: 500,
  button: "",
  subParagraph: [
    {
      title: "Direção",
      titleSize: "large",
      titleWeight: 700,
      paragraph: ``,
      paragraphSize: "medium",
      paragraphWeight: 500,
      button: "",
      subParagraph: [
        {
          title: "Presidente:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `Leonor Abranches Pinto`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
        {
          title: "Vice-presidente:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `Manuel Rafael`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
        {
          title: "Tesoureiro:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `Miguel Gouveia`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
      ],
    } as paragraph_i,
    {
      title: "Mesa da Assembleia",
      titleSize: "large",
      titleWeight: 700,
      paragraph: ``,
      paragraphSize: "medium",
      paragraphWeight: 500,
      button: "",
      subParagraph: [
        {
          title: "Presidente:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `Pedro Douwens`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
        {
          title: "Secretário 1:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `Maria do Carmo Caiado`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
        {
          title: "Secretário 2:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `Simão Andrade`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
      ],
    } as paragraph_i,
    {
      title: "Conselho Fiscal",
      titleSize: "large",
      titleWeight: 700,
      paragraph: ``,
      paragraphSize: "medium",
      paragraphWeight: 500,
      button: "",
      subParagraph: [
        {
          title: "Presidente:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `Joana Quintela`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
        {
          title: "Secretário 1:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `Pedro Pragosa`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
        {
          title: "Secretário 2:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `Catarina Lobão`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
      ],
    } as paragraph_i,
  ],
};

export const contactos: paragraph_i = {
  title: "CONTACTOS",
  titleSize: "larger",
  titleWeight: 800,
  paragraph: ``,
  paragraphSize: "medium",
  paragraphWeight: 500,
  button: "",
  subParagraph: [
    {
      title: "Direção",
      titleSize: "large",
      titleWeight: 700,
      paragraph: ``,
      paragraphSize: "medium",
      paragraphWeight: 500,
      button: "",
      subParagraph: [
        {
          title: "direcao@imprevisto.pt",
          titleSize: "medium",
          titleWeight: 500,
          paragraph: ``,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
      ],
    } as paragraph_i,
    {
      title: "Mesa da Assembleia",
      titleSize: "large",
      titleWeight: 700,
      paragraph: ``,
      paragraphSize: "medium",
      paragraphWeight: 500,
      button: "",
      subParagraph: [
        {
          title: "mesa@imprevisto.pt",
          titleSize: "medium",
          titleWeight: 500,
          paragraph: ``,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
      ],
    } as paragraph_i,
    {
      title: "Comunicação",
      titleSize: "large",
      titleWeight: 700,
      paragraph: ``,
      paragraphSize: "medium",
      paragraphWeight: 500,
      button: "",
      subParagraph: [
        {
          title: "comunicacao@imprevisto.pt",
          titleSize: "medium",
          titleWeight: 500,
          paragraph: ``,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
      ],
    } as paragraph_i,
    {
      title: "O Troco - Jornal",
      titleSize: "large",
      titleWeight: 700,
      paragraph: ``,
      paragraphSize: "medium",
      paragraphWeight: 500,
      button: "",
      subParagraph: [
        {
          title: "troco@imprevisto.pt",
          titleSize: "medium",
          titleWeight: 500,
          paragraph: ``,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
      ],
    } as paragraph_i,
    {
      title: "Redes Sociais",
      titleSize: "large",
      titleWeight: 700,
      paragraph: ``,
      paragraphSize: "medium",
      paragraphWeight: 500,
      button: "",
      subParagraph: [
        {
          title: "Instagram:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `ac.imprevisto`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
        {
          title: "YouTube:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `nenhum`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
        {
          title: "Spotify:",
          titleSize: "medium",
          titleWeight: 600,
          paragraph: `nenhum`,
          paragraphSize: "medium",
          paragraphWeight: 500,
          button: "",
          subParagraph: [],
        } as paragraph_i,
      ],
    } as paragraph_i,
  ],
};
