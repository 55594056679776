import { allEvents, event_i } from "../../store/eventsData";
import { Card } from "../../components/Card/Card";
import "./Arquivo.scss";

export default function Arquivo() {
  const events: event_i[] = allEvents;

  return (
    <div className="arquivo">
      <div className="arquivo-events">
        <div className="arquivo-events-group">
          <div className="arquivo-events-group-title">CONFERÊNCIAS</div>
          <div className="arquivo-events-group-cards">
            {events.map((event, key) => {
              if (event.type === "Conferência") {
                return (
                  <Card
                    title={event.title}
                    image={event.image}
                    date={event.date}
                    hour={event.hour}
                    type={"archived"}
                    key={key}
                    info={event}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="arquivo-events-group">
          <div className="arquivo-events-group-title">TEATROS</div>
          <div className="arquivo-events-group-cards">
            {events.map((event, key) => {
              if (event.type === "Teatro") {
                return (
                  <Card
                    title={event.title}
                    image={event.image}
                    date={event.date}
                    hour={event.hour}
                    type={"archived"}
                    key={key}
                    info={event}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="arquivo-events-group">
          <div className="arquivo-events-group-title">
            APRESENTAÇÕES DE LIVROS
          </div>
          <div className="arquivo-events-group-cards">
            {events.map((event, key) => {
              if (event.type === "Apresentação de Livro") {
                return (
                  <Card
                    title={event.title}
                    image={event.image}
                    date={event.date}
                    hour={event.hour}
                    type={"archived"}
                    key={key}
                    info={event}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="arquivo-events-group">
          <div className="arquivo-events-group-title">EXPOSIÇÕES</div>
          <div className="arquivo-events-group-cards">
            {events.map((event, key) => {
              if (event.type === "Exposição") {
                return (
                  <Card
                    title={event.title}
                    image={event.image}
                    date={event.date}
                    hour={event.hour}
                    type={"archived"}
                    key={key}
                    info={event}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="arquivo-events-group">
          <div className="arquivo-events-group-title">OUTROS</div>
          <div className="arquivo-events-group-cards">
            {events.map((event, key) => {
              if (event.type === "Outro") {
                return (
                  <Card
                    title={event.title}
                    image={event.image}
                    date={event.date}
                    hour={event.hour}
                    type={"archived"}
                    key={key}
                    info={event}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
