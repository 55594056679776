export interface event_i {
  type:
    | "Conferência"
    | "Teatro"
    | "Exposição"
    | "Apresentação de Livro"
    | "Outro";
  title: string;
  image: string;
  date: string;
  hour: string;
  commentTitle: string;
  comment: string;
  commentAuthor?: string;
  videoLink: string;
  secondImage: string;
  realDate: { year: number; month: number; day: number };
}

export const allEvents: event_i[] = [
  {
    type: "Conferência",
    title: "O que é a Universidade?",
    image: "/eventImages/oQueEAUniversidade.png",
    date: "QUINTA-FEIRA 9 DE NOVEMBRO",
    hour: "18H30",
    commentTitle: "Comentário sobre a conferência",
    comment:
      "hvjwbevbwerjve eruqwgerq gbqwig bqwuob qwiebf fqwu qwfqwq ehfb wqfr qbwf fwfqwbf qw ewu bwu qwiuf ui fbiqwefiqwf we fqwifb fqwe fiwqe fiuwuqef wqeb iwbqwef b qwofg b	weuie fwi efw efqwi fquwiefqwu ia f qwfgb iqoe lbqi gbwiuegb uyqbg qwufg q b wgbwrgbgwqrygbqeu qwh uqrbg qbr guqergqrr qb rg qwg qwuigbyarb gqrg bqerbg qrgb qwekgubquygybwq rg rughb w askgcnngcasngciszgcaer guier g ieruigeig s seig ajierr giquerng uqngunenr igaerpriuv b auis fnviae ignei nrgni aeirgniuqwer rgierrgui ernr giuae ngi eiurg qiuerguiugn eiubeinb ieuib sbn serinb isen b nseb niern irb nneuir vneuirbn weiejb nreiufrbn w eibnw iefbnn qoerrbwieob",
    commentAuthor: "Autor do comentário",
    videoLink: "",
    secondImage: "/eventImages/oQueEAUniversidade.png",
    realDate: { year: 2023, month: 11, day: 9 },
  },
  {
    type: "Conferência",
    title: "Chamados à Liberdade",
    image: "/eventImages/chamadosALiberdade.png",
    date: "QUARTA-FEIRA 3 DE ABRIL",
    hour: "18H",
    commentTitle: "",
    comment: "",
    videoLink: "",
    secondImage: "",
    realDate: { year: 2024, month: 4, day: 3 },
  },
  {
    type: "Conferência",
    title: "Condenar ou Redimir",
    image: "/eventImages/condenarOuRedimir.png",
    date: "QUINTA-FEIRA 4 DE ABRIL",
    hour: "18H",
    commentTitle: "",
    comment: "",
    videoLink: "",
    secondImage: "",
    realDate: { year: 2024, month: 4, day: 4 },
  },
  {
    type: "Conferência",
    title: "Olhar a Europa",
    image: "/eventImages/europa.png",
    date: "SEXTA-FEIRA 5 DE ABRIL",
    hour: "18H",
    commentTitle: "",
    comment: "",
    videoLink: "",
    secondImage: "",
    realDate: { year: 2024, month: 4, day: 5 },
  },
  {
    type: "Teatro",
    title: "Muito Barulho por Nada",
    image: "/eventImages/muitoBarulhoPorNada.png",
    date: "SÁBADO 6 DE ABRIL",
    hour: "17H | 21H",
    commentTitle: "",
    comment: "",
    videoLink: "",
    secondImage: "",
    realDate: { year: 2024, month: 4, day: 6 },
  },
];

export function getEventsByDate(year: number, month: number, day: number) {
  let events: event_i[] = [];

  allEvents.map((event) => {
    if (
      event.realDate.year === year &&
      event.realDate.month === month + 1 &&
      event.realDate.day === day
    ) {
      events.push(event);
    }
  });

  return events;
}

export function existsEventByDate(year: number, month: number, day: number) {
  let events: event_i[] = getEventsByDate(year, month, day);

  if (events.length === 0) {
    return false;
  } else {
    return true;
  }
}

export function getEventsSinceThisMonth() {
  let today = new Date();
  let events: event_i[] = [];

  allEvents.map((event) => {
    if (
      event.realDate.year > today.getFullYear() ||
      (event.realDate.year === today.getFullYear() &&
        event.realDate.month > today.getMonth() + 1) ||
      (event.realDate.year === today.getFullYear() &&
        event.realDate.month === today.getMonth() + 1 &&
        event.realDate.day >= 0)
    ) {
      events.push(event);
    }
  });

  return events;
}

export let eventInfo: event_i | null = null;

export function setEventInfo(event: event_i) {
  eventInfo = event;
}
