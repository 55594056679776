import "./roundButton.scss";

export const RoundButtonBW: React.FC<{ text: string }> = ({ text }) => {
  return (
    <button
      className="button"
      onClick={() => {
        fetch("http://localhost:4000/api/data")
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            console.log(data);
            // Do something with the response data
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }}
    >
      <span className="button-content">{text}</span>
    </button>
  );
};

export const RoundButton: React.FC<{ text: string }> = ({ text }) => {
  return (
    <button className="button round-btn-color">
      <span className="button-content round-btn-content-color">{text}</span>
    </button>
  );
};
